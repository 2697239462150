import { makeAutoObservable } from 'mobx';
import { getInitialData } from './getInitialData';
import {
  IHttpClient,
  IWixAPI,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import {
  DonationCampaign,
  GetDonationCampaignMetricsResponse,
} from '@wix/ambassador-donations-v1-donation-campaign/types';
import { GoalState } from '@/common/GoalState';
import { ILocaleKeys } from '@/locale-keys';
import { getCurrencyFormatter } from '@/common/utils/currency';

export type StandaloneGoalState = {
  campaign: DonationCampaign | undefined;
  metrics: GetDonationCampaignMetricsResponse | undefined;
  goal: ReturnType<typeof GoalState>;
};

export const createStateWithInitialData = async ({
  $props,
  $widget,
  httpClient,
  localeKeys,
  flowAPI,
  wixCodeApi,
}: {
  $props: { campaignId: string };
  $widget: { props: { campaignId: string } };
  httpClient: IHttpClient;
  localeKeys: ILocaleKeys;
  flowAPI: PlatformControllerFlowAPI;
  wixCodeApi: IWixAPI;
}) => {
  const campaignId = $props.campaignId ?? $widget.props.campaignId;
  const initialData = await getInitialData(httpClient, campaignId);
  return new State(initialData, localeKeys, flowAPI, wixCodeApi);
};

class State implements StandaloneGoalState {
  campaign: DonationCampaign | undefined;
  metrics: GetDonationCampaignMetricsResponse | undefined;
  public goal = GoalState(this, this.localeKeys, this.flowAPI.experiments);
  constructor(
    initialData: Pick<StandaloneGoalState, 'campaign' | 'metrics'>,
    private localeKeys: ILocaleKeys,
    private flowAPI: PlatformControllerFlowAPI,
    private wixCodeApi: IWixAPI,
  ) {
    this.campaign = initialData.campaign;
    this.metrics = initialData.metrics;
    makeAutoObservable(this, {
      goal: false,
    });
  }

  get showEmptyState() {
    return !this.campaign || !this.campaign.campaignGoalEnabled;
  }

  public get currencyFormatter() {
    const { wixCodeApi, flowAPI } = this;
    return getCurrencyFormatter({
      flowAPI,
      wixCodeApi,
    });
  }

  reset(data: Pick<StandaloneGoalState, 'campaign' | 'metrics'>) {
    this.campaign = data.campaign;
    this.metrics = data.metrics;
  }
}
