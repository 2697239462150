import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'GoalAmountWidget',
  props: {
    targetAmount: {
      type: WidgetPropertyType.NUMBER,
    },
    totalAmount: {
      type: WidgetPropertyType.NUMBER,
    },
    formattedTotalAmount: {
      defaultValue: undefined as string | undefined,
    },
    formattedTargetAmount: {
      defaultValue: undefined as string | undefined,
    },
    donationCount: {
      defaultValue: undefined as number | undefined,
    },
    timeLeftText: {
      defaultValue: undefined as string | undefined,
    },
  },
  methods: {
    getPrimaryText: {},
  },
  events: {},
});
