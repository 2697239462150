import { ComponentIds } from '../constants';
import { BindParams, StandaloneStore } from '../types';

const bindEmptyGoalState = ({
  $state,
  localeKeys,
}: StandaloneStore): BindParams => {
  const getTitle = () => {
    if (!$state.campaign) {
      return localeKeys.donations.widget.progressBar.empty.title();
    } else if (!$state.campaign.campaignGoalEnabled) {
      return localeKeys.donations.widget.progressBar.noGoal.title();
    } else {
      return '';
    }
  };

  const getSubtitle = () => {
    if (!$state.campaign) {
      return localeKeys.donations.widget.progressBar.empty.subtitle();
    } else if (!$state.campaign.campaignGoalEnabled) {
      return localeKeys.donations.widget.progressBar.noGoal.subtitle();
    } else {
      return '';
    }
  };

  return {
    [ComponentIds.EmptyStateTitle]: {
      text: getTitle,
    },
    [ComponentIds.EmptyStateSubtitle]: {
      text: getSubtitle,
    },
  };
};

export default bindEmptyGoalState;
