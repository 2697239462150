import { ComponentIds } from '../constants';
import { BindParams, StandaloneStore } from '../types';

const bindActiveGoalState = ({ $state }: StandaloneStore): BindParams => {
  return {
    [ComponentIds.GoalWidget]: {
      donationCount: () => $state.goal.donationCount,
      targetAmount: () => $state.goal.targetAmount,
      totalAmount: () => $state.goal.totalAmount,
      formattedTotalAmount: () => $state.goal.formattedTotalAmount,
      formattedTargetAmount: () => $state.goal.formattedTargetAmount,
      timeLeftText: () => $state.goal.timeLeftText,
    },
  };
};

export default bindActiveGoalState;
