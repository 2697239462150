import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';
import { handleDonateClickWrapper } from '@/components/DonationForm/services/handleDonateClick';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';
import { SPECS } from '@/common/constants/specs';

const bindDonateButton = (rootStore: RootStore): BindParams => {
  const { localeKeys, $w, experiments, $state } = rootStore;

  const getDonationButtonText = () => {
    return localeKeys.donations.widget.form.donateButton({
      amount: $state.selectedFormattedAmount,
      frequency: $state.selectedTranslatedFrequency,
      freeText: getTranslationOrUserText(
        rootStore.$state.donateButtonLabelBackup,
        localeKeys.donationForm.donate.button.defaultText(),
      ),
    });
  };

  if (rootStore.$state.donateButtonLabelBackup === null) {
    rootStore.$state.donateButtonLabelBackup = $w(Ids.DonateButton).label;
  }

  return {
    [Ids.DonateButton]: {
      label: () =>
        experiments.enabled(SPECS.oneFrequencyOptionFormDisplay)
          ? getDonationButtonText()
          : getTranslationOrUserText(
              $w(Ids.DonateButton).label,
              localeKeys.donationForm.donate.button.defaultText(),
            ),
      disabled: () => false,
      onClick: async () => handleDonateClickWrapper(rootStore),
    },
  };
};

export default bindDonateButton;
