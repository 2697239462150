import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds } from '@/components/DonationForm/constants';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import DonateButton from './DonateButton/DonateButton.bind';
import Frequency from './Frequency/Frequency.bind';
import Amount from './Amount/Amount.bind';
import Comment from './Comment/Comment.bind';
import Goal from './Goal/Goal.bind';
import { ILocaleKeys } from '@/locale-keys';

const bindFormState = (rootStore: RootStore): BindParams => {
  const { $w, controllerParams, localeKeys } = rootStore;
  const { flowAPI } = controllerParams;
  return {
    ...DonateButton(rootStore),
    ...Frequency(rootStore),
    ...Amount(rootStore),
    ...Comment(rootStore),
    ...Goal(rootStore),
    [ComponentIds.Title]: {
      text: () =>
        getTranslationOrUserText(
          $w(ComponentIds.Title).text,
          localeKeys.donationForm.title.defaultText(),
        ),
    },
    [ComponentIds.Description]: {
      text: () =>
        getTranslationOrUserText(
          $w(ComponentIds.Description).text,
          localeKeys.donationForm.description.defaultText(),
        ),
    },
    [ComponentIds.PremiumError]: {
      deleted: () => !rootStore.$state.showPremiumError,
    },
    [ComponentIds.PremiumErrorText]: {
      text: () => getErrorText(flowAPI, localeKeys),
    },
  };
};

const getErrorText = (
  flowAPI: PlatformControllerFlowAPI,
  localeKeys: ILocaleKeys,
): string => {
  if (flowAPI.environment.isPreview) {
    return localeKeys.donationForm.donate.button.previewNotification();
  }

  return flowAPI.environment.isViewer
    ? localeKeys.donationForm.donate.button.notPremium.errorMessage()
    : localeKeys.donationForm.donate.button.notPremium.editorNotification();
};

export default bindFormState;
