import { getDonationCampaignMetrics } from '@wix/ambassador-donations-v1-donation-campaign/http';
import { GetDonationCampaignMetricsResponse } from '@wix/ambassador-donations-v1-donation-campaign/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getMetrics = async (
  httpClient: IHttpClient,
  campaignId: string,
): Promise<GetDonationCampaignMetricsResponse> => {
  return httpClient
    .request(getDonationCampaignMetrics({ donationCampaignId: campaignId }))
    .then((res) => ({
      totalAmount: res.data.totalAmount,
      donationCount: res.data.donationCount,
    }))
    .catch((e) => {
      console.error(
        `Failed to fetch campaign metrics with id "${campaignId}"`,
        e,
      );
      return {
        totalAmount: undefined,
        donationCount: 0,
      };
    });
};
