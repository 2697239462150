import { getSubscriptionFrequencyTranslation } from '@/components/ThankYouPage/services/subscription';
import { ILocaleKeys } from '@/locale-keys';
import { PlatformControllerFlowAPI, IWixAPI } from '@wix/yoshi-flow-editor';
import { setupCurrencyFormatter } from '@/common/utils/currency';
import { TypInitialData } from '@/components/ThankYouPage/constants';
import { SubscriptionFrequency } from '@wix/ambassador-ecom-v1-order/types';

export interface ITypTranslationKeys {
  title: string;
  line2: string;
  line3: string;
}

export const getTypTranslationKeys = (
  flowAPI: PlatformControllerFlowAPI,
  wixCodeApi: IWixAPI,
  localeKeys: ILocaleKeys,
  data?: TypInitialData,
): ITypTranslationKeys => {
  const currencyFormatter = setupCurrencyFormatter({
    getCurrencyFormatterFromFlowAPI: flowAPI.getCurrencyFormatter,
    wixCodeApi,
  });

  const formattedAmount = (
    value: string = '00.0',
    currency: string = wixCodeApi.site.currency || '',
  ) =>
    currencyFormatter({
      value,
      currency,
    });

  if (flowAPI.environment.isViewer && data?.id) {
    return {
      title: localeKeys.thankYouPage.title({
        firstName: data.firstName,
        lastName: data.lastName,
      }),
      line2: localeKeys.thankYouPage.line1({
        amount: formattedAmount(data.amount ?? '', data.currency ?? ''),
        frequency: getSubscriptionFrequencyTranslation(
          data.frequency,
          localeKeys,
        ),
      }),
      line3: getLine3Translation(data, localeKeys),
    };
  }

  return {
    title: localeKeys.thankYouPage.title({
      firstName: localeKeys.thankYouPage.donorName.editor.dummyText(),
      lastName: '',
    }),
    line2: localeKeys.thankYouPage.line1({
      amount: formattedAmount(),
      frequency: localeKeys.thankYouPage.frequency.editor.dummyText(),
    }),
    line3: localeKeys.thankYouPage.line2({
      num: '1000',
    }),
  };
};

const getLine3Translation = (data: TypInitialData, localeKeys: ILocaleKeys) => {
  switch (data.frequency) {
    case 'ONE_TIME' as SubscriptionFrequency:
      if (data.paymentStatus === 'PAID') {
        return localeKeys.thankYouPage.line2({
          num: data.orderNumber,
        });
      } else {
        return localeKeys.thankYouPage.pendingPayment.line2();
      }
    default:
      return localeKeys.thankYouPage.recurringDonation.line2();
  }
};
