const isDefaultEnglishLabel = (label) =>
  defaultEnglishLabels.filter((elem) => elem === label).length > 0;

export const getTranslationOrUserText = (label, translation, override?) => {
  if (override && !isDefaultEnglishLabel(override)) {
    return override;
  }

  if (!label || isDefaultEnglishLabel(label)) {
    return translation;
  }

  return label;
};

const defaultEnglishLabels = [
  'Thank you for your support in helping us raise funds! While we didn’t reach our $10,000 goal, your contributions will still make a meaningful impact.',
  'Thank you for helping us raise $10,000! Funds will provide 500 meals, build 5 new classrooms, etc.',
  'Join our community!',
  'Amount',
  'Frequency',
  'Donate',
  'Comment (optional)',
  'Make a donation',
  'This is your campaign description. It’s a great place to tell visitors what this campaign is about, connect with your donors and draw attention to your cause.',
  'Edit Title',
  'Edit Description',
  'Edit Button Label',
];
