import { IHttpClient } from '@wix/yoshi-flow-editor';
import { getOrderById } from '@/common/api/getOrderById';
import { getSubscriptionById } from '@/common/api/getSubscriptionById';
import { TypInitialData } from '@/components/ThankYouPage/constants';
import { SubscriptionFrequency } from '@wix/ambassador-ecom-v1-order/types';
import { IWidgetControllerConfig } from '@wix/native-components-infra';

export const getTYPInitialData = async (
  controllerConfig: IWidgetControllerConfig,
  httpClient: IHttpClient,
): Promise<TypInitialData | undefined> => {
  const { orderId, objectType, campaignId } = getTYPUrlParams(controllerConfig);

  if (objectType === 'order') {
    const order = await getOrderById(orderId, httpClient);
    return {
      objectType,
      id: orderId,
      campaignId,
      checkoutId: order.checkoutId,
      paymentStatus: order.paymentStatus,
      catalogAppId: order.lineItems
        ? order.lineItems[0].catalogReference?.catalogItemId
        : undefined,
      firstName: order.billingInfo!.contactDetails!.firstName,
      lastName: order.billingInfo!.contactDetails!.lastName,
      amount: order!.priceSummary!.total!.amount,
      currency: order.currency,
      frequency: 'ONE_TIME' as SubscriptionFrequency,
      orderNumber: order.number,
    };
  } else if (objectType === 'subscription') {
    const subscription = await getSubscriptionById(orderId, httpClient);
    return {
      objectType,
      id: orderId,
      campaignId,
      checkoutId: subscription.checkoutId,
      catalogAppId: subscription.lineItems
        ? subscription.lineItems[0].productDetails?.productId
        : undefined,
      firstName: subscription.billingAddress!.fullName!.firstName,
      lastName: subscription.billingAddress!.fullName!.lastName,
      amount: subscription.totals?.total?.toString(),
      currency: subscription.storeSettings?.currency,
      frequency: subscription.subscriptionSettings
        ?.frequency as SubscriptionFrequency,
    };
  }
};

const getTYPUrlParams = (controllerConfig: IWidgetControllerConfig) => {
  const query = controllerConfig.wixCodeApi.location.query;
  return {
    orderId: query?.orderid,
    campaignId: query?.campaignid,
    objectType: query?.objecttype,
  };
};
