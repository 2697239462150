import {
  DONATIONS_APP_DEF_ID,
  DONATIONS_CHECKOUT_CONTENT_ID,
  ECOM_APP_DEF_ID,
} from '@/common/constants/app';
import { IWixAPI } from '@wix/yoshi-flow-editor';
import { thankYouPagePageName } from '@/editor-app/editor-ready/first-install/addthankYouPage';

export const navigateToCheckout = async (
  { wixCodeApi }: { wixCodeApi: IWixAPI },
  checkoutId: string,
  campaignId: string,
  usePlatformCheckoutContentTranslation: boolean,
) => {
  const ecomPublicApi = await wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
  const contentTranslationMethod = usePlatformCheckoutContentTranslation
    ? {
        contentAppId: DONATIONS_APP_DEF_ID,
        contentComponentId: DONATIONS_CHECKOUT_CONTENT_ID,
      }
    : {
        theme: 'donations',
      };
  await ecomPublicApi.navigate.toCheckout({
    checkoutId,
    disableContinueShopping: true,
    ...contentTranslationMethod,
    successUrl: `${wixCodeApi.location.baseUrl}/${thankYouPagePageName}/?orderid={orderId}%26campaignid=${campaignId}%26objecttype={objectType}`,
  });
};
