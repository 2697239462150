export const ComponentIds = {
  RootBox: '#box1',
  ListBox: '#radioGroupBox',
  Repeater: '#presetsRepeater',
  RepeaterItem: '#selectableContainer',
  RepeaterItemInput: '#selectableContainerInput',
  CustomAmountField: '#customTipFieldBox',
  CustomAmountFieldInput: '#customTipInput',
  CustomAmountBox: '#customTipStateBox',
  ErrorOuterBox: '#errorOuterBox',
  ErrorInnerBox: '#errorInnerBox',
  ErrorText: '#errorText',
  ErrorIcon: '#errorIcon',
  Label: '#amountTitle',
  CustomAmountStateBox: '#customTipStateBox',
  CustomAmountSelectedBtn: '#customTipSelectedBtn',
  CustomAmountDefaultBtn: '#customTipDefaultBtn',
  Default: '#default',
  Selected: '#selected',
  multiStateItem: '#msbItem',
  itemTextSelected: '#itemTextSelected',
  itemBoxSelected: '#box6',
  itemTextDefault: '#itemTextDefault',
  itemBoxDefault: '#box8',
  impactTextSelected: '#impactTextSelected',
  impactBoxSelected: '#box5',
  impactTextDefault: '#impactTextDefault',
  impactBoxDefault: '#box7',
  itemContainerSelected: '#SelectedContainer',
  itemContainerSelectedState: '#Selected',
  itemContainerDefaultState: '#Default',
  itemContainerDefault: '#defaultContainer',
  impactTextSingle: '#impactTextSingle',
} as const;

export const States = {
  Default: 'default',
  Selected: 'selected',
} as const;

export enum changeEventOrigin {
  CustomAmountField = 'custom-amount-field',
  SuggestionsList = 'suggestions-list',
  OtherAmountButton = 'other-amount-button',
}

export const MsbItemState = {
  Default: 'Default',
  Selected: 'Selected',
} as const;

export const CustomAmountItem = 'customAmountItem';
