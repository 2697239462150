import { RootStore } from '@/components/DonationForm/types';
import { StateIds } from '@/components/DonationForm/constants';

export const getCurrentState = (rootStore: RootStore) => {
  const { $state } = rootStore;
  if ($state.shouldShowEmptyState) {
    return StateIds.Empty;
  } else if (rootStore.controllerParams.flowAPI.environment.isEditor) {
    return StateIds.Form;
  } else if ($state.isGoalEnabled) {
    return getStateWhenHasGoal(rootStore);
  }
  return StateIds.Form;
};

const getStateWhenHasGoal = ({ $state }: RootStore) => {
  const { isTargetReached, isDeadlinePassed } = $state.goal;
  const { acceptDonationsAfterGoal, acceptDonationsAfterDeadline } =
    $state.campaign?.campaignGoal!;
  if (isTargetReached && !acceptDonationsAfterGoal) {
    return StateIds.GoalReached;
  } else if (
    !isTargetReached &&
    isDeadlinePassed &&
    !acceptDonationsAfterDeadline
  ) {
    return StateIds.GoalNotReached;
  }
  return StateIds.Form;
};
