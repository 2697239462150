import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';
import { SPECS } from '@/common/constants/specs';

const bindEmptyState = (rootStore: RootStore): BindParams => {
  const { localeKeys, $state, experiments } = rootStore;

  const getTitleText = () => {
    if ($state.isArchived) {
      return localeKeys.donationForm.emptyState.archived.title();
    } else if ($state.isNotFound) {
      return localeKeys.donationForm.emptyState.noneSelected.title();
    }
    return '';
  };

  const getBodyText = () => {
    if ($state.isArchived) {
      return localeKeys.donationForm.emptyState.archived.body();
    } else if ($state.isNotFound) {
      return localeKeys.donationForm.emptyState.noneSelected.body();
    }
    return '';
  };

  return {
    [Ids.EmptyStateTitle]: {
      text: getTitleText,
    },
    [Ids.EmptyStateBody]: {
      text: getBodyText,
    },
    [Ids.EmptyStateFrequencyTitle]: {
      text: () => localeKeys.donationForm.frequency.label.defaultText(),
    },
    [Ids.EmptyStateAmountTitle]: {
      text: () => localeKeys.donationForm.amount.label.defaultText(),
    },
    [Ids.EmptyStateNoteTitle]: {
      text: () => localeKeys.donationForm.note.title.defaultText(),
    },
    [Ids.EmptyStateDonateButton]: {
      label: () => localeKeys.donationForm.donate.button.defaultText(),
    },
  };
};

export default bindEmptyState;
