import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';

const bindGoalReachedState = (rootStore: RootStore): BindParams => {
  const { $w, $state, localeKeys } = rootStore;
  return {
    [Ids.GoalReachedTitle]: {
      text: () =>
        getTranslationOrUserText(
          $w(Ids.GoalReachedTitle).text,
          localeKeys.donations.widget.progressBar.reached.title(),
        ),
    },
    [Ids.GoalReachedBody]: {
      text: () =>
        getTranslationOrUserText(
          $w(Ids.GoalReachedBody).text,
          localeKeys.donations.widget.progressBar.reached.note(),
        ),
    },
    [Ids.GoalReachedGoalWidget]: {
      targetAmount: () => $state.goal.targetAmount,
      formattedTargetAmount: () => $state.goal.formattedTargetAmount,
      totalAmount: () => $state.goal.totalAmount,
      formattedTotalAmount: () => $state.goal.formattedTotalAmount,
      donationCount: () => $state.goal.donationCount,
      timeLeftText: () => $state.goal.timeLeftText,
    },
    [Ids.GoalReachedButton]: {
      label: () =>
        getTranslationOrUserText(
          $w(Ids.GoalReachedButton).label,
          localeKeys.donations.widget.progressBar.reached.cta(),
        ),
    },
  };
};

export default bindGoalReachedState;
