import { getCampaign } from '@/common/api/getCampaign';
import { GetDonationCampaignMetricsResponse } from '@wix/ambassador-donations-v1-donation-campaign/types';
import { getMetrics } from '@/common/api/getMetrics';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getInitialData = async (
  httpClient: IHttpClient,
  campaignId: string | undefined,
) => {
  if (campaignId === undefined) {
    return {
      campaign: undefined,
      metrics: undefined,
    };
  }
  const campaign = await getCampaign(httpClient, campaignId);
  let metrics: GetDonationCampaignMetricsResponse | undefined;
  const shouldFetchMetrics = !!campaign?.campaignGoalEnabled;
  if (shouldFetchMetrics) {
    metrics = await getMetrics(httpClient, campaign.id!);
  }

  return {
    campaign,
    metrics,
  };
};
