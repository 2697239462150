import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';

const bindExpiredState = (rootStore: RootStore): BindParams => {
  const { $w, $state, localeKeys } = rootStore;
  return {
    [Ids.ExpiredStateTitle]: {
      text: () =>
        getTranslationOrUserText(
          $w(Ids.ExpiredStateTitle).text,
          localeKeys.donations.widget.progressBar.deadlinePassed.title.$value(),
        ),
    },
    [Ids.ExpiredStateBody]: {
      text: () =>
        getTranslationOrUserText(
          $w(Ids.ExpiredStateBody).text,
          localeKeys.donations.widget.progressBar.deadlinePassed.note(),
        ),
    },
    [Ids.ExpiredStateGoalWidget]: {
      targetAmount: () => $state.goal.targetAmount,
      formattedTargetAmount: () => $state.goal.formattedTargetAmount,
      totalAmount: () => $state.goal.totalAmount,
      formattedTotalAmount: () => $state.goal.formattedTotalAmount,
      donationCount: () => $state.goal.donationCount,
      timeLeftText: () => $state.goal.timeLeftText,
    },
    [Ids.ExpiredStateButton]: {
      label: () =>
        getTranslationOrUserText(
          $w(Ids.ExpiredStateButton).label,
          localeKeys.donations.widget.progressBar.deadlinePassed.cta(),
        ),
    },
  };
};

export default bindExpiredState;
