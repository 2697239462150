import { getOrder } from '@wix/ambassador-ecom-v1-order/http';
import { Order } from '@wix/ambassador-ecom-v1-order/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getOrderById = async (
  orderId: string,
  httpClient: IHttpClient,
): Promise<Order> => {
  return httpClient
    .request(getOrder({ id: orderId }))
    .then((res) => res.data.order ?? {})
    .catch((e) => {
      console.error('Failed to get order', e);
      return {};
    });
};
