import { AmountOption } from '@/common/types';
import { SuggestedAmount } from '@wix/ambassador-donations-v1-donation-campaign/types';

const suggestedAmountToOption = (
  { amount, description }: SuggestedAmount,
  formatter?: (value: number | string) => string,
): AmountOption => {
  const amountValue = amount?.amount!;
  const formattedAmount = formatter
    ? formatter(amountValue)
    : amount?.formattedAmount!;
  return {
    value: `${amountValue}`,
    amountLabel: formattedAmount,
    label: formattedAmount,
    amountImpact: description,
  };
};

export const getSuggestedAmountMapper =
  (formatter?: (value: number | string) => string) =>
  (suggestedAmount: SuggestedAmount): AmountOption =>
    suggestedAmountToOption(suggestedAmount, formatter);
