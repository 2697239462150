import { WIX_ECOM } from '@wix/app-definition-ids';
import { MA_APP_IDS } from '@wix/members-area-integration-kit';

export const ECOM_APP_DEF_ID = WIX_ECOM;

export const DONATIONS_APP_DEF_ID = '333b456e-dd48-4d6b-b32b-9fd48d74e163';

export const DONATIONS_CHECKOUT_CONTENT_ID =
  'da4e05fc-2a03-44f9-aee6-f6398c5a7cb4';
export const formWidgetPresetsIds = {
  RTL: 'variants-lrngx529',
  LTR: 'variants-ldw685no1',
};

export const typWidgetPresetsIds = {
  RTL: 'variants-lrnhcuk0',
  LTR: 'variants-loya4pjw',
};

export const MEMBERS_AREA_DEFAULT_APPS = [
  MA_APP_IDS.MY_ORDERS,
  MA_APP_IDS.MY_ADDRESSES,
  MA_APP_IDS.MY_WALLET,
  MA_APP_IDS.MY_SUBSCRIPTIONS,
];
