import { BindParams, RootStore } from '@/components/DonationForm/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';

const bindGoal = (rootStore: RootStore): BindParams => {
  const { $state } = rootStore;
  const $goalState = $state.goal;
  return {
    [Ids.GoalAmount]: {
      deleted: () => !$state.isGoalEnabled,
      targetAmount: () => $goalState.targetAmount,
      formattedTargetAmount: () => $goalState.formattedTargetAmount,
      totalAmount: () => $goalState.totalAmount,
      formattedTotalAmount: () => $goalState.formattedTotalAmount,
      donationCount: () => $goalState.donationCount,
      timeLeftText: () => $goalState.timeLeftText,
    },
  };
};

export default bindGoal;
