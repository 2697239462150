import model from './model';
import {
  changeEventOrigin,
  ComponentIds as Ids,
  CustomAmountItem,
} from '@/components/AmountWidget/constants';
import { makeAutoObservable } from 'mobx';
import { getCurrency } from '@/common/utils/currency';
import { getCurrencies } from '@/common/api/getCurrencies';
import { AmountState, AmountStore } from './types';
import CustomAmount from './CustomAmount/CustomAmount.bind';
import SuggestionsList from './SuggestionsList/SuggestionsList.bind';
import { initLocaleKeys } from '@/common/utils/locale';
import { getErrorMessage, stringValueToNumber } from './utils';
import { getTranslationOrUserText } from '@/common/utils/getTranslationOrUserText';
import {
  clickOtherAmountSf,
  donationAmountChangeInSf,
  applyOtherAmountSf,
} from '@wix/bi-logger-wixdonations/v2';
import { SPECS } from '@/common/constants/specs';

export default model.createController(
  ({ $props, $bindAll, $widget, $w, flowAPI, controllerConfig }) => {
    let $widgetState: AmountState;
    const currencyCode = getCurrency(controllerConfig);
    return {
      pageReady: async () => {
        const currencies = await getCurrencies(flowAPI.httpClient);
        const localeKeys = initLocaleKeys(flowAPI.translations.i18n);
        const { symbol } =
          currencies.find(({ code }) => code === currencyCode) ?? {};
        const fireChangeEvent = (origin: changeEventOrigin, value?: string) => {
          switch (origin) {
            case changeEventOrigin.SuggestionsList:
              flowAPI.bi?.report(donationAmountChangeInSf({}));
              break;
            case changeEventOrigin.OtherAmountButton:
              flowAPI.bi?.report(clickOtherAmountSf({}));
              break;
            case changeEventOrigin.CustomAmountField:
              flowAPI.bi?.report(applyOtherAmountSf({}));
          }

          const numberValue = stringValueToNumber(value);
          $widget.fireEvent('change', numberValue);
        };
        const clearInputValidity = () => {
          $w(Ids.CustomAmountFieldInput).resetValidityIndication();
        };

        $widgetState = makeAutoObservable<AmountState>({
          list: makeAutoObservable({
            selectedValue: undefined,
            get hasOptions() {
              return $props.options.length > 0;
            },
            get value() {
              return $widgetState.list.selectedValue ?? $props.defaultValue;
            },
            get selectedImpactText() {
              return $props.options[this.selectedIndex]?.amountImpact;
            },
            get selectedIndex() {
              if (
                $widgetState.customAmount.isSelected &&
                this.value !== CustomAmountItem
              ) {
                return -1;
              }
              const index = $props.options.findIndex(
                ({ value }) => value === $widgetState.list.value,
              );
              return index >= 0 ? index : 0;
            },
            reset() {
              $widgetState.list.selectedValue = undefined;
            },
          }),
          get currencySymbol() {
            return symbol ?? '';
          },
          customAmount: makeAutoObservable({
            isSelected: false,
            value: undefined,
            isDirty: false,
            get errorMessage() {
              return $widgetState.customAmount.isDirty
                ? getErrorMessage($widgetState.customAmount.value, localeKeys, {
                    min: $props.minCustomAmount,
                    max: $props.maxCustomAmount,
                  })
                : '';
            },
            get showInput() {
              return (
                $props.customAmountEnabled &&
                ($widgetState.customAmount.isSelected ||
                  !$widgetState.list.hasOptions ||
                  (!flowAPI.environment.isViewer &&
                    !flowAPI.environment.isPreview))
              );
            },
            get hasError() {
              return (
                $widgetState.customAmount.isDirty &&
                !!$widgetState.customAmount.errorMessage
              );
            },
            reset() {
              $widgetState.customAmount.isSelected = false;
              $widgetState.customAmount.value = undefined;
              $w(Ids.CustomAmountFieldInput).value = '';
              $widgetState.customAmount.isDirty = false;
              clearInputValidity();
            },
          }),
          reset() {
            $widgetState.list.reset();
            $widgetState.customAmount.reset();
          },
        });

        const amountStore: AmountStore = {
          $props,
          $widgetState,
          $w,
          fireChangeEvent,
          clearInputValidity,
          localeKeys,
          isAmountImpactEnabled: flowAPI.experiments.enabled(
            SPECS.AmountImpactUoU,
          ),
        };

        $bindAll({
          ...CustomAmount(amountStore),
          ...SuggestionsList(amountStore),
          [Ids.Label]: {
            deleted: () => !$props.shouldShowLabel,
            text: () =>
              getTranslationOrUserText(
                $w(Ids.Label).text,
                localeKeys.donationForm.amount.label.defaultText(),
                $props.label,
              ),
          },
          [Ids.ListBox]: {
            accessibility: {
              ariaAttributes: {
                labelledBy: () => $w(Ids.Label),
              },
              role: () => 'radiogroup',
            },
          },
          [Ids.impactTextSingle]: {
            text: () => $widgetState.list.selectedImpactText,
            deleted: () =>
              !amountStore.isAmountImpactEnabled ||
              $widgetState.customAmount.isSelected,
          },
        });
        clearInputValidity();
      },
      exports: {
        focus: () => {
          const { customAmount } = $widgetState;
          if (customAmount.showInput) {
            $widgetState.customAmount.isDirty = true;
            $w(Ids.CustomAmountFieldInput).focus();
          } else {
            ($w(Ids.Repeater) as any).focus();
          }
        },
        isValid: () => {
          const { customAmount } = $widgetState;
          if (customAmount.showInput) {
            return $w(Ids.CustomAmountFieldInput).validity.valid;
          } else {
            return $w(Ids.Repeater).selectedIndex >= 0;
          }
        },
        getValue: () => {
          const { customAmount } = $widgetState;
          if (customAmount.showInput) {
            return stringValueToNumber(customAmount.value);
          } else {
            const { value } = $props.options[$w(Ids.Repeater).selectedIndex];
            return stringValueToNumber(value);
          }
        },
        reset: () => {
          $widgetState?.reset();
        },
      },
    };
  },
);
