import { initLocaleKeys } from '@/common/utils/locale';
import model from './model';
import { RootStore } from './types';
import { getInitialData } from './services/getInitialData';
import { RootState } from './services/RootState';
import FormState from '@/components/DonationForm/FormState/FormState.bind';
import EmptyState from '@/components/DonationForm/EmptyState/EmptyState.bind';
import ExpiredState from '@/components/DonationForm/ExpiredState/ExpiredState.bind';
import GoalReachedState from '@/components/DonationForm/GoalReachedState/GoalReachedState.bind';
import { ComponentIds } from './constants';
import { reportWidgetLoad } from '@/components/DonationForm/services/bi';
import { getCurrentState } from './services/getCurrentState';

export default model.createController((controllerParams) => {
  const { $w } = controllerParams;
  return {
    pageReady: async () => {
      const { $props, $bindAll, flowAPI, controllerConfig, $widget } =
        controllerParams;
      const rootStore = {
        controllerParams,
        httpClient: flowAPI.essentials.httpClient,
        fedops: flowAPI.fedops,
        $w,
        experiments: flowAPI.experiments,
        wixCodeApi: controllerConfig.wixCodeApi,
        localeKeys: initLocaleKeys(flowAPI.translations.i18n),
        biLogger: flowAPI.bi,
      } as RootStore;
      rootStore.$state = new RootState(
        rootStore,
        $props,
        $widget.props,
        flowAPI,
        controllerConfig,
      );

      const setInitialStateData = async () => {
        const { campaign, isPremium, metrics } = await getInitialData(
          rootStore,
        );

        if (campaign) {
          reportWidgetLoad($w, flowAPI, campaign);
          rootStore.$state.reset(
            campaign,
            flowAPI.environment.isViewer,
            isPremium,
            metrics,
          );
        }
      };
      controllerParams.$widget.onPropsChanged(setInitialStateData);
      await setInitialStateData();

      $bindAll({
        ...FormState(rootStore),
        ...EmptyState(rootStore),
        ...ExpiredState(rootStore),
        ...GoalReachedState(rootStore),
        [ComponentIds.MultiStateBox]: {
          currentState: () => getCurrentState(rootStore),
        },
        [ComponentIds.RootBox]: {
          deleted: () =>
            flowAPI.environment.isViewer &&
            rootStore.$state.shouldShowEmptyState,
        },
      });
    },
    updateWidgetViewState: (viewStateId) => {
      void $w(ComponentIds.MultiStateBox).changeState(viewStateId);
    },
    exports: {},
  };
});
