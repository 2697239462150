import model from './model';
import { ComponentIds as Ids } from './constants';
import { makeAutoObservable } from 'mobx';
import { initLocaleKeys } from '@/common/utils/locale';

export default model.createController(({ $bindAll, $props, flowAPI, $w }) => {
  const $widgetState = makeAutoObservable({
    get barValue() {
      if (!$props.targetAmount) {
        return 0;
      } else {
        return Math.floor(
          (($props.totalAmount ?? 0) / $props.targetAmount) * 100,
        );
      }
    },
    get hasDonationCount() {
      return $props.donationCount !== undefined;
    },
    get hasTimeLeftText() {
      return !!$props.timeLeftText;
    },
  });

  const localeKeys = initLocaleKeys(flowAPI.translations.i18n);

  return {
    pageReady: async () => {
      $bindAll({
        [Ids.ProgressBar]: {
          value: () => $widgetState.barValue,
        },
        [Ids.PercentageText]: {
          text: () => `${$widgetState.barValue}%`,
        },
        [Ids.DonatedAmountText]: {
          text: () =>
            localeKeys.donations.widget.progressBar.amountRaised.$value({
              numRaised: $props.formattedTotalAmount,
            }) ?? '',
          deleted: () => !$props.formattedTotalAmount,
        },
        [Ids.GoalAmountText]: {
          text: () => $props.formattedTargetAmount ?? '',
          deleted: () => !$props.formattedTargetAmount,
        },
        [Ids.TimeLeftText]: {
          text: () => $props.timeLeftText ?? '',
          deleted: () => !$widgetState.hasTimeLeftText,
        },
        [Ids.DonationCountText]: {
          text: () =>
            localeKeys.donations.widget.progressBar.dynamicDonationsAmount({
              numDonations: $props.donationCount ?? 0,
            } as any),
          deleted: () => !$widgetState.hasDonationCount,
        },
        [Ids.MetricsOuterBox]: {
          deleted: () =>
            !$widgetState.hasDonationCount && !$widgetState.hasTimeLeftText,
        },
      });
    },
    exports: {
      getPrimaryText: () => $w(Ids.DonatedAmountText).text,
    },
  };
});
