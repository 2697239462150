export const ComponentIds = {
  RootBox: '#box1',
  GoalWidget: '#goalWidget',
  MultiStateBox: '#multiStateBox1',
  ActiveState: '#activeState',
  EmptyState: '#emptyState',
  EmptyStateTitle: '#emptyTitle',
  EmptyStateSubtitle: '#emptySubtitle',
} as const;

export const enum StateIds {
  Active = 'activeState',
  Empty = 'emptyState',
}
