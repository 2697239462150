export const ComponentIds = {
  RootBox: '#rootBox',
  ProgressBar: '#progressBar',
  ProgressBarBox: '#progressBarBox',
  HeadlineBox: '#headlineBox',
  DonatedAmountText: '#donatedAmountText',
  DonatedAmountBox: '#donatedAmountBox',
  GoalAmountText: '#goalAmountText',
  GoalAmountBox: '#goalAmountBox',
  BarAndMetricsBox: '#barAndMetricsBox',
  FooterBox: '#footerBox',
  MetricsInnerBox: '#metricsInnerBox',
  MetricsOuterBox: '#metricsOuterBox',
  PercentageText: '#percentageText',
  PercentageBox: '#percentageBox',
  TimeLeftText: '#daysLeftText',
  TimeLeftBox: '#daysLeftBox',
  DonationCountText: '#donationCountText',
  DonationCountBox: '#donationCountBox',
} as const;
